$base-color: #333;
$ghost-color: #3a3a3a;
$animation-duration: 1500ms; 

@mixin background-gradient { 
  background-image: linear-gradient(90deg, $base-color 0px, $ghost-color 40px, $base-color 80px)!important;
  background-size: 80vw!important;
}

@keyframes ghost-lines { 
  0%   { background-position: -100px;  }
  40%  { background-position: 40vw;    }
  100% { background-position: 60vw;    }
}

@mixin animate-ghost-line {
  animation: ghost-lines $animation-duration infinite linear!important;
}