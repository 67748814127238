/*@tailwind preflight;*/

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.font-light {
  font-weight: 300;
}

.h-16 {
  height: 1.6rem;
}

.h-40 {
  height: 4rem;
}

.h-48 {
  height: 4.8rem;
}

.h-64 {
  height: 6.4rem;
}

.h-96 {
  height: 9.6rem;
}

.h-full {
  height: 100%;
}

.text-12 {
  font-size: 1.2rem;
}

.text-13 {
  font-size: 1.3rem;
}

.text-14 {
  font-size: 1.4rem;
}

.text-15 {
  font-size: 1.5rem;
}

.text-16 {
  font-size: 1.6rem;
}

.m-24 {
  margin: 2.4rem;
}

.my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

.my-32 {
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
}

.my-48 {
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-mx-8 {
  margin-left: -0.8rem;
  margin-right: -0.8rem;
}

.mr-4 {
  margin-right: 0.4rem;
}

.mb-4 {
  margin-bottom: 0.4rem;
}

.ml-4 {
  margin-left: 0.4rem;
}

.mt-8 {
  margin-top: 0.8rem;
}

.mr-8 {
  margin-right: 0.8rem;
}

.ml-8 {
  margin-left: 0.8rem;
}

.ml-12 {
  margin-left: 1.2rem;
}

.mt-16 {
  margin-top: 1.6rem;
}

.mr-16 {
  margin-right: 1.6rem;
}

.mb-16 {
  margin-bottom: 1.6rem;
}

.ml-16 {
  margin-left: 1.6rem;
}

.mt-24 {
  margin-top: 2.4rem;
}

.mb-24 {
  margin-bottom: 2.4rem;
}

.mt-32 {
  margin-top: 3.2rem;
}

.mb-32 {
  margin-bottom: 3.2rem;
}

.mt-64 {
  margin-top: 6.4rem;
}

.mb-64 {
  margin-bottom: 6.4rem;
}

.mb-128 {
  margin-bottom: 12.8rem;
}

.min-h-64 {
  min-height: 6.4rem;
}

.min-h-screen {
  min-height: 100vh;
}

.min-w-48 {
  min-width: 4.8rem;
}

.opacity-50 {
  opacity: 0.5;
}

.overflow-hidden {
  overflow: hidden;
}

.p-0 {
  padding: 0;
}

.p-16 {
  padding: 1.6rem;
}

.p-24 {
  padding: 2.4rem;
}

.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.py-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
}

.pl-0 {
  padding-left: 0;
}

.pr-8 {
  padding-right: 0.8rem;
}

.pr-12 {
  padding-right: 1.2rem;
}

.pl-16 {
  padding-left: 1.6rem;
}

.pt-20 {
  padding-top: 2rem;
}

.pl-20 {
  padding-left: 2rem;
}

.pt-24 {
  padding-top: 2.4rem;
}

.pb-24 {
  padding-bottom: 2.4rem;
}

.pl-24 {
  padding-left: 2.4rem;
}

.pl-40 {
  padding-left: 4rem;
}

.pl-56 {
  padding-left: 5.6rem;
}

.pb-64 {
  padding-bottom: 6.4rem;
}

.pl-72 {
  padding-left: 7.2rem;
}

.pl-80 {
  padding-left: 8rem;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-white {
  --text-opacity: 1;
  color: #FFFFFF;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.text-gray-500 {
  --text-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--text-opacity));
}

.uppercase {
  text-transform: uppercase;
}

.normal-case {
  text-transform: none;
}

.underline {
  text-decoration: underline;
}

.visible {
  visibility: visible;
}

.whitespace-no-wrap {
  white-space: nowrap;
}

.w-16 {
  width: 1.6rem;
}

.w-32 {
  width: 3.2rem;
}

.w-40 {
  width: 4rem;
}

.w-48 {
  width: 4.8rem;
}

.w-64 {
  width: 6.4rem;
}

.w-96 {
  width: 9.6rem;
}

.w-1\/2 {
  width: 50%;
}

.w-full {
  width: 100%;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.gap-16 {
  grid-gap: 1.6rem;
  gap: 1.6rem;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.auto-cols-auto {
  grid-auto-columns: auto;
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
          transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@media (min-width: 600px) {

  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:mt-24 {
    margin-top: 2.4rem;
  }

  .sm\:w-1\/4 {
    width: 25%;
  }
}

@media (min-width: 960px) {

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-end {
    align-items: flex-end;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:ml-24 {
    margin-left: 2.4rem;
  }

  .md\:ml-32 {
    margin-left: 3.2rem;
  }

  .md\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  .md\:w-1\/5 {
    width: 20%;
  }
}

@media (min-width: 1280px) {

  .lg\:pr-0 {
    padding-right: 0;
  }

  .lg\:pl-0 {
    padding-left: 0;
  }
}
