@import './normalize.css';
@import './custom-reset.css';
@import './ghost-animation.scss';

* {
  outline: none !important;
}

html {
  font-size: 62.5%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  background-color: #111;
  color: #fff;
}

body {
  font-size: 14px;
  line-height: 1.4;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 16px;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 12px;
}

a[role='button'] {
  text-decoration: none;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 600px) {
  #scrollbar::-webkit-scrollbar {
    width: 16px;
    background-color: transparent;
  }

  #scrollbar::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    box-shadow: inset 0 0 0 10px #aaa;
    border-radius: 20px;
  }
}

form label {
  z-index: 99;
}

.bg-ghost {
  @include background-gradient;
  @include animate-ghost-line;
}

.line-clamp-2 {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
}

.line-clamp-1 {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
}

@import 'tailwind.css';
